import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const SignIn = resolve => require(['../pages/sign-in'], resolve)
const Index = resolve => require(['../pages/index'], resolve)
const WorkDetail = resolve => require(['../pages/work/detail'], resolve)
const Agreement = resolve => require(['../pages/agreement'], resolve)
let path = [
    {
        path: '/',
        name: Index.name + 'default',
        component: Index,
        meta: {auth: true}
    },
    {
        path: '/work/:id',
        name: WorkDetail.name,
        component: WorkDetail,
        meta: {auth: true}
    },
    {
        path: '/manage/:id',
        name: Index.name,
        component: Index,
        meta: {auth: true}
    },
    {
        path: '/sign-in',
        name: SignIn.name,
        component: SignIn
    },
    {
        path: '/agreement/:id',
        name: Agreement.name,
        component: Agreement
    },
]


let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: path
})

export default router;
