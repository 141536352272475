<template>
  <div class="ikaxia_admin" id="ikaxia_admin">
    <router-view ref="container"/>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'ikaxia_admin',
  }

</script>

<style lang="less">
  h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
  }
  @import "./assets/style/basic";
  .margin{
    margin-top: 20px !important;
  }
  .ant-dropdown-menu{
    background-color: #1d1f22;
    border-radius: 3px;
    padding:8px;
    box-sizing: border-box;
    .ant-dropdown-menu-item{
      border-radius: 3px;
      padding:0 7px;
      height: 38px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      color: #fff;
      font-size: 14px;
      margin-top: 8px;
      &:first-child{
        margin-top: 0;
      }
      &:hover{
        background-color: #2a3039
      }
    }
  }
</style>
